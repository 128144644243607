<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
          <a-form-item label="一卡通名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入一卡通名称"></a-input>
          </a-form-item>

          <a-form-item label="卡类型" name="type" class="ui-form__item">
            <a-select v-model:value="formState.type" style="width: 180px;" allow-clear placeholder="请选择卡类型">
              <a-select-option :value="1">普通卡</a-select-option>
              <a-select-option :value="2">主题卡</a-select-option>
              <a-select-option :value="3">礼品卡</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_preferential_list_add']" type="primary" style="margin-right: 10px;" @click="onAdd">新增一卡通</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :scroll="{ x: 1500 }">
					<template #bodyCell="{ column, record }">
            <template v-if="column.key === 'cardType'">
              <div v-if="record.cardType === 1">普通卡</div>
              <div v-if="record.cardType === 2">主题卡</div>
              <div v-if="record.cardType === 3">礼品卡</div>
            </template>
            <template v-if="column.key === 'cardImage'">
              <a-image :width="50" :src="record.cardImage" />
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_preferential_list_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_list_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_list_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>禁用</a-menu-item>
										</div>
                    <div v-permission="['coupon_preferential_list_cardBatchCreat']" @click="onCreateCard(record)">
                      <a-menu-item>
                        批量生成卡
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_list_delete']" @click="onDelete(record)">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

      <a-modal v-model:visible="showCardCode" title="批量生成卡" width="800px" @ok="onCreateCardOk">
        <a-spin :spinning="loading">
          <a-form ref="createForm" scrollToFirstError :model="cardCodeModelRef" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
            <a-form-item label="面值" name="parPrice" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="cardCodeModelRef.parPrice" placeholder="请输入面值"></a-input-number> 元
            </a-form-item>

            <a-form-item label="可领取数量" name="num" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="cardCodeModelRef.num" placeholder="请输入可领取数量"></a-input-number>
            </a-form-item>

            <a-form-item label="领取截止日期" name="endDate" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-date-picker v-model:value="cardCodeModelRef.endDate"></a-date-picker>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
		</a-spin>
	</div>
	<temp v-else :id="id" :isEdit="isEdit" @back="onBack"></temp>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import temp from './temp.vue';
  import {
    getUnionCardArchiveCode,
    getUnionCardList,
    updateUnionCard
  } from "@/service/modules/coupon";
  import {deleteUnionCard} from "../../../../service/modules/coupon";
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
        id: 0,
        showCardCode: false,
        isEdit: false,
				loading: false,
        cardCodeModelRef: {},
        searchData: {},
				formState: {},
				list: [],
				columns: [{
					title: '一卡通名称',
					dataIndex: 'title',
				}, {
					title: '卡类型',
					key: 'cardType',
				}, {
					title: '封面',
          key: 'cardImage',
				},{
					title: '剩余库存',
          dataIndex: 'stockNum',
				}, {
					title: '已售',
          dataIndex: 'saleNum',
				}, {
					title: '优先级',
          dataIndex: 'sort',
				}, {
					title: '状态',
          key: 'isDisabled',
				}, {
					title: '操作',
					key: 'action',
          fixed: 'right',
          width: 150
				}],
        showModal: false,
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10','20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
      this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.getData();
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
      onSearch() {
        this.pagination.current = 1;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.getData();
      },
      async getData() {
        this.loading = true;
        try {
          let ret = await getUnionCardList({
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            ...this.searchData
          })
          this.loading = false;
          if(ret.code === 200) {
            this.list = ret.data.list;
            this.pagination.total = ret.data.totalCount;
          }
        } catch(e) {
          this.loading = false;
        }
      },
			onAdd() {
				this.showModal = true;
        this.isEdit = false;
			},
			onEdit(item) {
        this.showModal = true;
        this.isEdit = true;
        this.id = item.id;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该兑换券吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updateUnionCard({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
      onCreateCard(item) {
        this.id = item.id;
        this.showCardCode = true;
      },
      async onCreateCardOk() {
        this.$refs.createForm.validateFields().then(async () => {
          this.loading = true;
          let postData = JSON.parse(JSON.stringify(this.cardCodeModelRef));
          postData.unionId = this.id;
          if(postData.endDate) {
            postData.endDate = this.moment(postData.endDate).unix();
          }
          try {
            let ret = await getUnionCardArchiveCode(postData);
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.showCardCode = false;
              this.cardCodeModelRef = {};
            }
          } catch(e) {
            this.loading = false;
          }
        })
      },
      onDelete(item) {
        this.$confirm({
          title: '提示',
          content: '确认要删除该一卡通吗？',
          onOk: async () => {
            this.loading = true;
            try {
              let ret = await deleteUnionCard({id: item.id});
              this.loading = false;
              if(ret.code === 200) {
                this.$message.success('操作成功');
                this.getData();
              }
            } catch(e) {
              this.loading = false;
            }
          }
        })
      },
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
