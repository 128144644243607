<template>
	<div>
		<Header :title="isEdit ? '编辑一卡通' : '新增一卡通'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				:label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">

				<a-form-item label="一卡通名称" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.title" placeholder="请输入一卡通名称" />
				</a-form-item>

        <a-form-item label="卡类型" name="cardType" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="卡类型仅仅区分展示位置">
          <a-radio-group v-model:value="modelRef.cardType">
            <a-radio :value="1">普通卡</a-radio>
            <a-radio :value="2">主题卡</a-radio>
            <a-radio :value="3">礼品卡</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="卡封面" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：750 : 500">
          <a-upload
              v-model:file-list="fileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="onChange"
          >
            <div v-if="fileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="可选面值" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-button type="primary" @click="onAdd">新增</a-button>
          <a-form-item-rest>
            <div v-if="list.length" style="margin: 10px 0 30px; width: 600px;">
              <a-table rowKey="id" :columns="columns" :dataSource="list" size="small" :pagination="false">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'parPrice'">
                    <a-input-number style="width: 140px;" :min="0" :precision="0" v-model:value="record.parPrice" placeholder="请输入金额"></a-input-number>
                  </template>
                  <template v-if="column.key === 'action'">
                    <a-button v-if="index !== 0" type="link" @click="moveUp(index)">上移</a-button>
                    <a-button v-if="index !== list.length - 1" type="link" @click="moveDown(index)">下移</a-button>
                    <a-button type="link" @click="onDelete(record, index)">删除</a-button>
                  </template>
                </template>
              </a-table>
            </div>
          </a-form-item-rest>
        </a-form-item>

        <a-form-item label="库存" name="stockNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
          <a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.stockNum" placeholder="请输入库存" />
        </a-form-item>

        <a-form-item label="基础已售" name="baseSaleNum">
          <a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.baseSaleNum" placeholder="请输入基础已售" />
        </a-form-item>

        <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
          <a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.sort" placeholder="请输入优先级" />
        </a-form-item>
				
				<a-form-item label="是否启用" name="isDisabled">
          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
				</a-form-item>
				
				<a-form-item label="购买说明" name="buyDesc">
          <div style="position: relative;z-index: 99;">
            <Editor v-model:value="modelRef.buyDesc"></Editor>
          </div>
				</a-form-item>
				
				<a-form-item>
					<div style="text-align: center;">
						<a-button type="primary" @click="onSubmit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
  import Editor from '@/components/editor/editor';
  import { Icon } from '@/components/icon/icon.js';
  import {getUnionCardDetail, saveUnionCard, updateUnionCard} from "../../../../service/modules/coupon";
	export default {
		components: {Header, Icon, Editor},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
        fileList: [],
				loading: false,
				modelRef: {
          isDisabled: false,
				},
        list: [],
        columns: [{
          title: '金额',
          key: 'parPrice',
        },{
          title: '操作',
          key: 'action',
          fixed: 'right',
        }],
			}
		},
		created() {
      if(this.isEdit) {
        this.getData();
      }
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
      async getData() {
        this.loading = true;
        try {
          let ret = await getUnionCardDetail({
            id: this.id
          })
          this.loading = false;
          if(ret.code === 200) {
            ret.data.isDisabled = ret.data.isDisabled ? false : true;
            ret.data.parPriceList.forEach(item => {
              this.list.push({
                parPrice: item
              })
            })
            this.modelRef = ret.data;
          }
          if(ret.data.cardImage){
            this.fileList = ret.data.cardImage.split(',').map(item=>{
              return {
                url: item,
              }
            })
          }
        } catch(e) {
          this.loading = false;
        }
      },
      onChange(info) {
        if (info.file.status === 'done') {
          this.fileList = info.fileList;
          const $fileList = [...this.fileList];
          this.fileList = $fileList.map(file => {
            if (file.response) {
              file.url = file.response.data.imgUrl;
            }
            return file;
          });
        }
      },
      async onSubmit() {
        this.$refs.form.validateFields().then(async () => {
          if(this.list.length === 0) {
            this.$message.warning('可选面值不能为空！');
            return;
          }
          let postData = JSON.parse(JSON.stringify(this.modelRef));
          let priceList = [];
          this.list.forEach(item => {
            priceList.push(item.parPrice);
          })
          postData.parPrice = priceList.join(',');
          postData.isDisabled = postData.isDisabled ? 0 : 1;
          const imgList = [];
          this.fileList.forEach(item=>{
            imgList.push(item.url);
          });
          postData.cardImage = imgList.join(',');
          this.loading = true;
          try {
            let ret;
            if(this.isEdit) {
              postData.id = this.id;
              ret = await updateUnionCard(postData);
            } else {
              ret = await saveUnionCard(postData);
            }
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.onBack(true);
            }
          } catch(e) {
            this.loading = false;
          }
        })
      },
      onAdd() {
        this.list.push({
          parPrice: null
        })
      },
      // 上移
      moveUp(index){
        const temp = this.list[index];
        this.list.splice(index, 1);
        this.list.splice(index - 1, 0, temp);
      },
      // 下移
      moveDown(index){
        const temp = this.list[index];
        this.list.splice(index, 1);
        this.list.splice(index + 1, 0, temp);
      },
      onDelete(item, index) {
        this.list.splice(index, 1);
      },
		}
	}
</script>

<style scoped>

</style>
